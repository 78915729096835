import client from "./client";

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg"
const fetchHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
};
const requiredInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
    // login_user_id: localStorage.getItem('id') || 0,
    // login_user_type: localStorage.getItem('user_type') || "client",
};

export const cartAction = async ({id, user_type, actionType, part_id, cart_part_id}) => {
    // console.log(id)
    const details = {
        ...requiredInput,
        login_user_id: id,
        login_user_type: user_type || "client",
        action_type: actionType,
        part_id: part_id,
        quantity: 1
    }
    if (cart_part_id) {
        details.cart_part_id = cart_part_id
    }
    // console.log(details)
    try {
        return await client.post("/dealer/product/cart-action", details, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        // console.log(response.data)

        return {error: error.message || error};
    }
};

export const cart = async ({id, user_type, coupon, couponTrue, token_part, payment_mode}) => {
    // console.log(couponTrue)
    const details = {
        ...requiredInput,
        login_user_id: id,
        login_user_type: user_type || "client",
    }
    if (couponTrue) {
        details.coupon_code = coupon
    }
    if (token_part) {
        details.token_part = token_part
    }
    if (token_part) {
        details.token_part = token_part
    }
    if (payment_mode) {
        details.payment_mode = payment_mode
    }
    try {
        return await client.post("/dealer/product/cart", details, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};

        return {error: error.message || error};
    }
};

export const cartCheckout = async ({
                                       userId,
                                       userType,
                                       addressId,
                                       coupon,
                                       couponTrue,
                                       address,
                                       payment_mode,
                                       token_payment,
                                       payment_id,
                                       token_part
                                   }) => {
    // console.log(coupon)
    let details = {
        ...requiredInput,
        login_user_id: userId,
        login_user_type: userType || "client",
    }
    if (token_part) {
        details.token_part = token_part
    }
    if (addressId) {
        details.address_id = addressId
    }
    if (couponTrue) {
        details.coupon_code = coupon
    }
    // if (payment_id) {
    //     details.payment_id = payment_id
    // }
    if (token_payment) {
        details.token_payment = token_payment
    }
    if (address) {
        details = {...details, ...address}
    }
    if (payment_mode) {
        details.payment_mode = payment_mode;
    } else {
        details.payment_mode = "cod";
    }

    // console.log(details)

    // return details;
    try {
        return await client.post("/dealer/product/cart-checkout", details, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        // console.log(response.data)

        return {error: error.message || error};
    }
};