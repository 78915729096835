import React, {useEffect, useState} from "react";
import {
    category_list,
    model,
    part_list,
    product_listing_by_id,
    product_model,
} from "../../api/general";
import MoreButton from "./MoreButton";
import {useNavigate} from "react-router-dom";
import DialogTempNotAvailable from "../DialogTempNotAvailable";
import {useAuth, useNotification} from "../../hooks";
import {book_service} from "../../api/booking";
import {Button, CircularProgress} from "@mui/material";

export default function TabsForm1() {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [brand_id, setbrand_id] = useState();
    const [model_slug, setModel_slug] = useState();
    const [brand_slug, setBrand_slug] = useState();
    const [category_slug, setCategory_slug] = useState();
    const [modalId, setModalId] = useState();
    const {setShowLoginModel} = useNotification();
    const {authInfo} = useAuth();
    const {setOpenBackDrop} = useNotification();
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate();
    const getCategories = async () => {
        setOpenBackDrop(true);
        const {data} = await category_list({is_service: "yes"});
        setCategories(data.data);
        setOpenBackDrop(false);
    };

    useEffect(() => {
        getCategories();
        setBrands([]);
        setModels([]);
    }, []);

    const getBrands = async () => {
        setOpenBackDrop(true);
        if (categoryId) {
            const {data} = await model({category_id: categoryId});
            setBrands(data?.data || []);
        }
        setOpenBackDrop(false);

    };
    useEffect(() => {
        getBrands();
    }, [categoryId]);

    const [notAvOpen, setNotAvOpen] = useState(false);
    const onClick = async (e) => {
        e.preventDefault();
        setLoading(true);

        let url = "/book-repair?";


        if (modalId) {
            const inputData = {
                brand_id: brand_id,
                category_id: categoryId,
                model_id: modalId,
                id: authInfo.profile.id,
                user_type: authInfo.profile.user_type,
                // inputData.token_order = token;
                step: "device-info"
            };

            const {data, error} = await book_service(inputData);

            // console.log("response--", data)
            if (data.error === false) {

                navigate("/book-repair?token=" + data?.data?.token + "&" + "step=" + data?.data.step + "&" + "step-count=" + 1)
                setLoading(false);
            }
            setLoading(false);
        } else {
            if (category_slug) url += "&category-slug=" + category_slug;
            if (brand_slug) url += "&brand-slug=" + brand_slug;
            if (model_slug) url += "&model-slug=" + model_slug;
            navigate(url);
        }


    };
    const handleChangeCategory = (e) => {
        setCategoryId(e.target.value);
        setCategory_slug(e.target.selectedOptions[0].getAttribute('slug'))
    };
    const handleChangeBrand = async (event) => {
        setOpenBackDrop(true);

        setBrand_slug(event.target.selectedOptions[0].getAttribute('slug'))
        setbrand_id(event.target.value);
        const {data} = await product_model({brand_id: event.target.value});
        setModels(data?.data || []);
        setOpenBackDrop(false);

    };
    const handleChangeModel = (event) => {
        setModel_slug(event.target.selectedOptions[0].getAttribute('slug'));
        setModalId(event.target.value)
    };

    return (
        <div>
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen}/>
            <form className="">
                <select onChange={handleChangeCategory} className="form-select my-5" aria-label="Select Category">
                    <option className="tabs-dropdown-placeholder">
                        Select Category
                    </option>
                    {categories?.map((element) => {
                        return <option key={element?.token} value={element.id}
                                       slug={element.slug}>{element.title}</option>;
                    })}
                </select>
                <select onChange={handleChangeBrand} className="form-select my-5" aria-label="Select Brand">
                    <option>Select Brand</option>
                    {brands === null || !brands?.length ? (
                        <></>
                        // <option value="1">Please Select Category</option>
                    ) : (
                        brands?.map((element) => {
                            return <option key={element?.token} value={element.id}
                                           slug={element.slug}>{element.title}</option>;
                        })
                    )}
                </select>
                <select onChange={handleChangeModel} className="form-select my-4" aria-label="Select Model">
                    <option>Select Model</option>
                    {models === null || !models?.length ? (
                        <option value="1">Please Select Model</option>
                    ) : (
                        models?.map((element) => {
                            return <option key={element?.token} value={element.id}
                                           slug={element.slug}>{element.title}</option>;
                        })
                    )}
                </select>
                <div className="d-flex justify-content-center align-item-center">
                    {
                        authInfo.profile.id ?
                            <button
                                disabled={loading} type="button"
                                className={"sign-up-button float-end " + (loading && 'disabled-button')}
                                onClick={onClick}>
                                {loading &&
                                    <CircularProgress size={16} color={"inherit"}/>} &nbsp; Book Repair
                            </button> :
                            <button className={'sign-up-button'} onClick={() => setShowLoginModel(true)} type="button">
                                Book Repair</button>
                    }

                </div>
            </form>
        </div>
    );
}
